<template>
  <div>
    <HeaderPanel
      title="LOG"
      :filter="filter"
      placeholder=""
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="id == 3 ? fields2 : fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(reponse_code)="{ item }">
          <span
            v-if="item.plugin_type_id == 9"
            :class="
              item.response_code == '0000' ? 'text-success' : 'text-error'
            "
            >{{ item.response_code == "0000" ? "Success" : "Failed" }}</span
          >
          <span
            v-else
            :class="item.response_code == 'OK' ? 'text-success' : 'text-error'"
            >{{ item.response_code == "OK" ? "Success" : "Failed" }}</span
          >
        </template>
        <!-- <template v-slot:cell(url)="{ item }">
          <span
            :class="item.response_code == 'OK' ? 'text-success' : 'text-error'"
            >{{ item.response_code == "OK" ? "Success" : "Failed" }}</span
          >
        </template> -->
        <template v-slot:cell(created_time)="{ item }">
          {{ $moment(item.created_time).format("DD/MM/YYYY hh:mm:ss A") }}
        </template>
        <template v-slot:cell(action)="{ item }">
          <font-awesome-icon
            class="cursor-pointer"
            :icon="['fas', 'search']"
            @click="openModal(item)"
          />
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
      <FooterAction
        routePath="/setting/plugins"
        @submit="sendForm(0)"
        :hideSubmit="true"
      />
      <b-modal v-model="modal" hide-footer title="Preview Body" size="lg">
        <template v-if="id == 3">
          <div class="label-text mb-2">Noted</div>
          <div class="mb-2">{{ note || "-" }}</div>
        </template>
        <div class="label-text mb-2">Send Body</div>
        <pre v-if="sendBody">{{ sendBody }}</pre>
        <div v-else>-</div>
        <div class="label-text my-2">Response Body</div>
        <pre v-if="resposeBody">{{ resposeBody }}</pre>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
export default {
  name: "admin",
  components: {
    HeaderPanel,
  },
  data() {
    return {
      modal: false,
      filter: {
        search: "",
        status: [],
        role: [],
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "id",
          label: "ID",
          class: "w-1",
        },
        {
          key: "created_time",
          label: "Create Time",
          class: "text-center",
        },
        // {
        //   key: "url",
        //   label: "Request URL",
        // },
        {
          key: "reponse_code",
          label: "Response Code",
        },

        {
          key: "action",
          label: "Preview Body",
          class: "w-2",
        },
      ],
      fields2: [
        {
          key: "id",
          label: "ID",
          class: "w-1",
        },
        {
          key: "created_time",
          label: "Create Time",
          class: "text-center",
        },
        {
          key: "url",
          label: "Request URL",
        },
        {
          key: "reponse_code",
          label: "Response Code",
        },

        {
          key: "action",
          label: "Preview Body",
          class: "w-2",
        },
      ],
      items: [],
      resposeBody: {},
      sendBody: {},
      note: "",
      id: this.$route.params.id,
    };
  },
  created() {
    this.getLogs();
  },

  methods: {
    openModal(item) {
      try {
        console.log(item);
        if (item.plugin_type_id == 9) {
          this.note = item.note;
          this.resposeBody = item.response_body;
          this.sendBody = item.send_body;
        } else {
          let body = JSON.parse(item.response_body);
          this.note = item.note;
          this.resposeBody = body;
          this.sendBody = JSON.parse(item.send_body);
        }
        this.modal = true;
      } catch (error) {
        console.log(error);
        this.modal = true;
      }
    },
    async getLogs() {
      this.isBusy = true;
      var { page, take } = this.filter;
      const response = await this.axios(
        `/Plugin/pluginLogList/${this.$route.params.id}?page=${page}&take=${take}`,
        this.filter
      );

      this.isBusy = false;
      this.items = response.data.detail.data;
      this.rows = response.data.detail.count;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getLogs();
    },
    pagination(page) {
      this.filter.page = page;
      this.getLogs();
    },
  },
};
</script>

<style>
.image-logo {
  border-radius: 0.5rem;
  width: 75px;
  height: auto;
  aspect-ratio: 1;
}
.card-footer {
  padding: 0.75rem 1rem;
}
.setting-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdown-menu {
  min-width: unset;
  width: max-content;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
code {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  /* height: 500px; */
  display: block;
  overflow-x: hidden;
}
</style>
